import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.scss';
import CustomerListPage from './components/Customer/CustomerListPage';
import NewCustomerPage from './components/Customer/NewCustomer/NewCustomerPage';
import CustomerShowPage from './components/Customer/CustomerShow/CustomerShowPage';
import OrderPage from './components/Order/OrderPage';
import CheckoutPage from './components/Checkout/CheckoutPage';
import Header from './components/Header/Header';
import PaidOrdersPage from './components/Order/Paid/PaidOrdersPage';
import LoginPage from './components/Login/LoginPage';
import InitPage from './components/InitPage';
import AdminOpenOrdersPage from './components/Admin/OpenOrders/AdminOpenOrdersPage';
import DailyTotalsPage from './components/Admin/DailyTotals/DailyTotalsPage';
import NewUserPage from './components/NewUser/NewUserPage';
import TextMessagePage from './components/Admin/TextMessage/TextMessagePage';
import AdminMordyTotalsPage from './components/Admin/MordyTotals/AdminMordyTotalsPage';

const App = () => {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<InitPage />} />
        <Route path="/home" element={<CustomerListPage />} />
        <Route path="/new-customer" element={<NewCustomerPage />} />
        <Route path="/customer" element={<CustomerShowPage />} />
        <Route path="/order" element={<OrderPage />} />
        <Route path="/checkout" element={<CheckoutPage />} />
        <Route path="/paid-orders" element={<PaidOrdersPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/new-user" element={<NewUserPage />} />
        <Route path="/admin/customer-list" element={<CustomerListPage />} />
        <Route path="/admin/open-orders" element={<AdminOpenOrdersPage />} />
        <Route path="/admin/daily-totals" element={<DailyTotalsPage />} />
        <Route path="/admin/text-message" element={<TextMessagePage />} />
        <Route path="/admin/mordy-totals" element={<AdminMordyTotalsPage />} />
      </Routes>
    </div>
  );
};

export default App;
