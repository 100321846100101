import React, { FC } from 'react';
import EsrogImage from '../../assets/images/esrog.png';
import Optional from '../Optional/Optional';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { resetUser, UserType } from '../../slices/userSlice/userSlice';
import { useNavigate } from 'react-router';
import { logoutAction } from '../../actions/logoutAction';
import { resetCustomerList } from '../../slices/customerListSlice/customerListSlice';
import { resetCustomer } from '../../slices/customerSlice/customerSlice';
import {
  resetDiscount,
  resetMordy,
  resetNote,
  resetOrder,
  resetVoucher,
} from '../../slices/orderSlice/orderSlice';
import { resetItems } from '../../slices/itemsSlice/itemsSlice';
import { resetLineItems } from '../../slices/lineItemsSlice/lineItemsSlice';
import { useLocation } from 'react-router-dom';
import './Header.scss';

const Header: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { username, admin, jwt } = useSelector<RootState, UserType>(({ user }) => user);

  if (pathname === '/home' || pathname === '/admin/open-orders') {
    dispatch(resetCustomerList());
    dispatch(resetCustomer());
    dispatch(resetOrder());
    dispatch(resetItems());
    dispatch(resetLineItems());
    dispatch(resetNote());
    dispatch(resetVoucher());
    dispatch(resetDiscount());
    dispatch(resetMordy());
  }

  if (pathname === '/paid-orders') {
    dispatch(resetCustomerList());
    dispatch(resetItems());
    dispatch(resetLineItems());
    dispatch(resetNote());
    dispatch(resetVoucher());
    dispatch(resetDiscount());
    dispatch(resetMordy());
  }

  if (!username && jwt) {
    dispatch(resetUser());
  }

  const handleLogout = () => {
    dispatch(logoutAction(navigate));
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <img src={EsrogImage} width="35" height="35" className="d-inline-block align-top" alt="" />
      <span className="navbar-brand mb-0 h1">
        Esrog Shuk, hello {username || '... please login to continue'}!
        <Optional renderIf={username}>
          (
          <span
            style={{ textDecoration: 'underline', cursor: 'pointer', color: 'blue' }}
            onClick={handleLogout}>
            logout
          </span>
          )
        </Optional>
      </span>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div className="navbar-nav">
          <Optional renderIf={username}>
            <div className="nav-item nav-link btn btn-primary text-light" onClick={() => navigate('/')}>
              New Order
            </div>
          </Optional>
          <Optional renderIf={admin}>
            <div className="nav-link" onClick={() => navigate('/admin/open-orders')}>
              Open Orders
            </div>
            <div className="nav-link" onClick={() => navigate('/admin/daily-totals')}>
              Daily Totals
            </div>
            <div className="nav-link" onClick={() => navigate('/admin/mordy-totals')}>
              Mordy Totals
            </div>
            <div className="nav-link" onClick={() => navigate('/admin/customer-list')}>
              Master Customer List
            </div>
            <div className="nav-link" onClick={() => navigate('/admin/text-message')}>
              Send Text Message
            </div>
          </Optional>
        </div>
      </div>
    </nav>
  );
};

export default Header;
