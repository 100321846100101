import React, { FC } from 'react';
import { CustomerInfoProps } from './CustomerInfoProps';
import './CustomerInfo.scss';
import { useNavigate } from 'react-router';

const CustomerInfo: FC<CustomerInfoProps> = ({ customer }) => {
  const navigate = useNavigate();
  const formatPhone = phoneNumber => phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');

  const handleClick = () => {
    navigate(`/customer?id=${customer.id}`);
  };

  return (
    <div className="customer-info" onClick={handleClick}>
      <div className="name">{`${customer.firstName} ${customer.lastName}`}</div>
      <div>{formatPhone(customer.phoneNumber)}</div>
      <div>{customer.email}</div>
    </div>
  );
};

export default CustomerInfo;
