import { CreateNewUserActionType } from '../actions/createNewUserAction';
import { call, put, takeEvery } from '@redux-saga/core/effects';
import { CREATE_NEW_USER, SEND_TEXT_MESSAGE } from '../actions/actionsTypes';
import { loginAction } from '../actions/loginAction';
import { postData, postDataWithHeaders } from '../httpClient';
import { SendTextMessageActionType } from '../actions/sendTextMessageAction';

export function* sendTextMessage(action: SendTextMessageActionType) {
  try {
    const response = yield call(postDataWithHeaders, '/api/v1/admin/send-text-message', action.headers, {
      text_body: action.message,
      twilio_image: action.imageUrl,
      numbers: action.phoneNumbers,
    });

    if (response.status === 'ok') {
      alert(`Text Message Sent`);
    }
  } catch (e) {
    alert('Sorry, something happened! Please try again');
  }
}

export function* watchSendTextMessage() {
  yield takeEvery(SEND_TEXT_MESSAGE, sendTextMessage);
}
