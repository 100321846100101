import { call, put, takeEvery } from '@redux-saga/core/effects';
import { getDataWithHeaders } from '../httpClient';
import { GET_ORDER } from '../actions/actionsTypes';
import {
  setOrder,
  updateOrderDiscount,
  updateOrderMordy,
  updateOrderNote,
  updateOrderVoucher,
} from '../slices/orderSlice/orderSlice';
import { GetOrderAction } from '../actions/getOrderAction';
import { LineItemType, setOpenOrderLineItems } from '../slices/lineItemsSlice/lineItemsSlice';
import camelcaseKeys from 'camelcase-keys';
import { getAvailableItemsAction } from '../actions/getAvailableItemsAction';
import { resetUser } from '../slices/userSlice/userSlice';

export function* getOrder(action: GetOrderAction) {
  try {
    const orderResponse = yield call(
      getDataWithHeaders,
      `/api/v1/orders/${action.orderId}`,
      action.headers
    );
    yield put(setOrder(camelcaseKeys(orderResponse)));

    const lineItemResponse = yield call(
      getDataWithHeaders,
      `/api/v1/line_items/?order_id=${action.orderId}`,
      action.headers
    );
    const lineItems = [] as LineItemType[];
    lineItemResponse.forEach(lineItem => {
      lineItem.lineId = Math.floor(Math.random() * 10000);
      lineItems.push(camelcaseKeys(lineItem));
    });
    yield put(setOpenOrderLineItems(lineItems));

    if (orderResponse.note_id) {
      const noteResponse = yield call(
        getDataWithHeaders,
        `/api/v1/notes/${orderResponse.note_id}`,
        action.headers
      );

      yield put(updateOrderNote(camelcaseKeys(noteResponse)));
    }

    if (orderResponse.discount_id) {
      const discountResponse = yield call(
        getDataWithHeaders,
        `/api/v1/discounts/${orderResponse.discount_id}`,
        action.headers
      );

      yield put(updateOrderDiscount(camelcaseKeys(discountResponse)));
    }

    if (orderResponse.voucher_id) {
      const voucherResponse = yield call(
        getDataWithHeaders,
        `/api/v1/vouchers/${orderResponse.voucher_id}`,
        action.headers
      );

      yield put(updateOrderVoucher(camelcaseKeys(voucherResponse)));
    }

    if (orderResponse.mordy_id) {
      const mordyResponse = yield call(
        getDataWithHeaders,
        `/api/v1/mordys/${orderResponse.mordy_id}`,
        action.headers
      );

      yield put(updateOrderMordy(camelcaseKeys(mordyResponse)));
    }

    if (orderResponse.status === 'paid') {
      yield put(getAvailableItemsAction());
      action.navigate(`/checkout?id=${action.orderId}`);
    } else {
      action.navigate(`/order?id=${action.orderId}`);
    }
  } catch (e) {
    yield put(resetUser());
    alert('Sorry, Login Expired! Please Login again');
    action.navigate('/login');
  }
}

export function* watchGetOrder() {
  yield takeEvery(GET_ORDER, getOrder);
}
