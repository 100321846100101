import { CreateNewUserActionType } from '../actions/createNewUserAction';
import { call, put, takeEvery } from '@redux-saga/core/effects';
import { CREATE_NEW_USER } from '../actions/actionsTypes';
import { loginAction } from '../actions/loginAction';
import { postData } from '../httpClient';

export function* createNewUser(action: CreateNewUserActionType) {
  try {
    const response = yield call(postData, '/users', {
      display_name: action.username,
      username: action.username.toLowerCase(),
      password: action.password,
      password_confirmation: action.passwordConfirmation,
    });

    if (response.status === 'created') {
      alert(`User: ${action.username} created! Logging in`);
      yield put(loginAction(action.username, action.password, action.navigate));
    }
  } catch (e) {
    alert('Sorry, something happened! Please try again');
    action.navigate('/new-user');
  }
}

export function* watchCreateNewUser() {
  yield takeEvery(CREATE_NEW_USER, createNewUser);
}
