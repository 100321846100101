import { NavigateFunction } from 'react-router';
import * as types from './actionsTypes';

export interface CreateNewUserActionType {
  type: string;
  username: string;
  password: string;
  passwordConfirmation: string;
  navigate: NavigateFunction;
}

export const createNewUserAction = (
  username: string,
  password: string,
  passwordConfirmation: string,
  navigate: NavigateFunction
) => ({
  type: types.CREATE_NEW_USER,
  username,
  password,
  passwordConfirmation,
  navigate,
});
