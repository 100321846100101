import React, { FC, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { MordyProps } from './MordyProps';
import axios from 'axios';
import { resetMordy, updateOrderMordy } from '../../../slices/orderSlice/orderSlice';

const MordyTile: FC<MordyProps> = ({ order, headers, total }) => {
  const dispatch = useDispatch();
  const [mordyChecked, setMordyChecked] = useState<boolean>(!!order.mordy?.id);
  const [hatzalah, setHatzalah] = useState<boolean>(!!order.mordy?.hatzalah);
  const [amount, setAmount] = useState(order.mordy?.amount || '');

  useMemo(() => {
    if (order.mordy?.id && !mordyChecked) {
      axios.delete(`/api/v1/mordys/${order.mordy.id}`, headers).then(() => {
        dispatch(resetMordy());
        setAmount('');
        setHatzalah(false);
      });
    }
  }, [mordyChecked, order.mordy]);

  const handleMordy = ({ target: { checked } }) => {
    setMordyChecked(checked);
    if (checked) {
      setAmount('');
    }
  };

  const handleHatzalah = ({ target: { checked } }) => {
    setHatzalah(checked);
    setAmount(checked ? Math.ceil(+total * 0.1) : '');
  };

  const handleAmount = ({ target: { value } }) => {
    setAmount(value.replace(/[^0-9]/, ''));
  };

  const addMordy = () => {
    if (order.mordy?.id) {
      axios
        .patch(
          `/api/v1/mordys/${order.mordy.id}`,
          {
            amount,
            hatzalah,
          },
          headers
        )
        .then(response => dispatch(updateOrderMordy(response.data)));
    } else {
      axios
        .post(
          '/api/v1/mordys',
          {
            order_id: order.id,
            amount,
            hatzalah,
          },
          headers
        )
        .then(response => dispatch(updateOrderMordy(response.data)));
    }
  };

  return (
    <div className="mordy-tile pull-left">
      <input
        type="checkbox"
        checked={mordyChecked}
        onChange={handleMordy}
        disabled={order.status === 'paid'}
      />{' '}
      Mordy &nbsp;
      {mordyChecked && (
        <>
          {' '}
          <input
            type="checkbox"
            checked={hatzalah}
            onChange={handleHatzalah}
            disabled={!!order.mordy?.id}
          />{' '}
          Hatzalah &nbsp;
          <input
            type="text"
            onChange={handleAmount}
            placeholder="$"
            style={{ width: '64px', height: '23px', marginLeft: '8px' }}
            maxLength={3}
            value={amount}
            disabled={hatzalah || !!order.mordy?.id}
          />
          <button
            className="btn btn-warning"
            style={{ marginLeft: '8px' }}
            onClick={addMordy}
            disabled={!amount || order.status === 'paid' || !!order.mordy?.id}>
            Apply
          </button>
        </>
      )}
    </div>
  );
};

export default MordyTile;
