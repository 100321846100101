import * as types from './actionsTypes';

export interface SendTextMessageActionType {
  type: string;
  message: string;
  imageUrl: string;
  headers: any;
  phoneNumbers?: string[];
}

export const sendTextMessageAction = (
  message: string,
  imageUrl: string,
  headers: any,
  phoneNumbers?: string
) => ({
  type: types.SEND_TEXT_MESSAGE,
  message,
  imageUrl,
  headers,
  phoneNumbers,
});
