import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../app/store';
import { UserType } from '../slices/userSlice/userSlice';
import { useNavigate } from 'react-router';

const InitPage: FC = () => {
  const navigate = useNavigate();
  const { id, tokenExp } = useSelector<RootState, UserType>(({ user }) => user);

  useEffect(() => {
    navigate(id ? '/home' : '/login');
  }, [tokenExp, id]);

  return <div />;
};

export default InitPage;
