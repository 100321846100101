import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import Optional from '../Optional/Optional';
import { createNewUserAction } from '../../actions/createNewUserAction';

const NewUserPage: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const handleUsername = ({ target }) => {
    setUsername(target.value);
  };

  const handlePassword = ({ target }) => {
    setPassword(target.value);
  };

  const handlePasswordConfirmation = ({ target }) => {
    setPasswordConfirmation(target.value);
  };

  const handleClick = () => {
    dispatch(createNewUserAction(username, password, passwordConfirmation, navigate));
  };

  return (
    <div className="login-page">
      <div>
        <input type="text" placeholder="username" value={username} onChange={handleUsername} />
      </div>
      <div>
        <input type="password" placeholder="password" value={password} onChange={handlePassword} />
      </div>
      <div>
        <input
          type="password"
          placeholder="password confirmation"
          value={passwordConfirmation}
          onChange={handlePasswordConfirmation}
        />
        <Optional renderIf={password !== passwordConfirmation}>
          <div className="text-danger">* Passwords must match!</div>
        </Optional>
        <Optional renderIf={password === passwordConfirmation}>
          <br />
          <br />
        </Optional>
      </div>
      <div>
        <button
          type="button"
          className="btn btn-primary"
          disabled={!username || !password || !passwordConfirmation || password !== passwordConfirmation}
          onClick={handleClick}>
          Create New User
        </button>
      </div>
    </div>
  );
};

export default NewUserPage;
