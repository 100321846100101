import { useJwtHeaders } from '../../../hooks/useJwtHeaders';
import React, { useState } from 'react';
import Optional from '../../Optional/Optional';
import './TextMessagePage.scss';
import { sendTextMessageAction } from '../../../actions/sendTextMessageAction';
import { useDispatch } from 'react-redux';

const TextMessagePage = () => {
  const dispatch = useDispatch();
  const headers = useJwtHeaders();
  const [showTestNumberBox, setShowTestNumberBox] = useState<boolean>(false);
  const [testNumbers, setTestNumbers] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [imageUrl, setImageUrl] = useState<string>('');

  const handleCheckbox = () => {
    setShowTestNumberBox(!showTestNumberBox);
    setTestNumbers('');
  };

  const handleClick = () => {
    dispatch(sendTextMessageAction(message, imageUrl, headers, testNumbers));
  };

  return (
    <div className="text-message-page">
      <div className="container">
        <div className="row">
          <div className="col column-left">
            <input type="checkbox" checked={showTestNumberBox} onChange={handleCheckbox} /> Test text
            message
            <Optional renderIf={showTestNumberBox}>
              <div>
                comma seperated numbers
                <br />
                <input
                  type="text"
                  placeholder="(111)111-1111, (111)111-1111, (111)111-1111"
                  onChange={e => setTestNumbers(e.target.value)}
                />
              </div>
            </Optional>
            <br />
            <div>
              <textarea placeholder="message" onChange={e => setMessage(e.target.value)} />
            </div>
            <br />
            <div>
              <input type="text" placeholder="image url" onChange={e => setImageUrl(e.target.value)} />
            </div>
            <br />
            <div>
              <button type="button" className="btn btn-success" onClick={handleClick}>
                Send Text
              </button>
            </div>
          </div>
          <div className="col column-right">
            <Optional renderIf={imageUrl}>
              <img className="text-image" src={imageUrl} alt="text image" />
            </Optional>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextMessagePage;
