export const GET_CUSTOMER_LIST = 'GET_CUSTOMER_LIST';
export const SAVE_NEW_CUSTOMER = 'SAVE_NEW_CUSTOMER';
export const START_NEW_ORDER = 'START_NEW_ORDER';
export const GET_AVAILABLE_ITEMS = 'GET_AVAILABLE_ITEMS';
export const SEND_LINE_ITEMS = 'SEND_LINE_ITEMS';
export const GET_LINE_ITEMS = 'GET_LINE_ITEMS';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const GET_ORDER = 'GET_ORDER';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const CREATE_NEW_USER = 'CREATE_NEW_USER';
export const SEND_TEXT_MESSAGE = 'SEND_TEXT_MESSAGE';
