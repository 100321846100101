import { LoginActionType } from '../actions/loginAction';
import { call, put, takeEvery } from '@redux-saga/core/effects';
import { LOGIN } from '../actions/actionsTypes';
import { postData } from '../httpClient';
import { setUser } from '../slices/userSlice/userSlice';
import camelcaseKeys from 'camelcase-keys';
import { getCustomerListAction, GetCustomerListActionType } from '../actions/customerListAction';
import { getCustomerList } from './customerListSaga';
import { useJwtHeaders } from '../hooks/useJwtHeaders';

export function* login(action: LoginActionType) {
  try {
    const response = yield call(postData, '/sessions', {
      username: action.username.toLowerCase(),
      password: action.password,
    });

    if (response.status === 'created') {
      yield put(setUser({ ...camelcaseKeys(response.user), jwt: response.jwt }));
      action.navigate('/home');
    } else {
      alert(`Invalid login request with status ${response.status}, please try again`);
      action.navigate('/login');
    }
  } catch (e) {}
}

export function* watchLogin() {
  yield takeEvery(LOGIN, login);
}
